import { createTheme } from '@material-ui/core';
import colors from '../variable.module.scss';

const theme = createTheme({
  typography: {
    fontFamily: [`"Source Sans Pro"`, `sans-serif`].join(','),
    fontSize: 17,
  },
  overrides: {
    MuiTypography: {
      h6: { fontWeight: 600 },
      colorTextSecondary: {
        color: colors.hit_gray_a,
        fontSize: '13px',
      },
    },
    MuiPaper: {
      root: {
        top: 'calc(65% - 5px) !important',
        height: '35%',
      },
    },
    MuiListItem: {
      root: {
        justifyContent: 'center',
        borderBottom: `1px solid ${colors.hit_gray_a}`,
        '&$selected': {
          fontWeight: 600,
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.2rem',
      },
    },
    MuiLink: {
      root: {
        fontSize: '20px',
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
  },
});

export default theme;
