// utils/API.js
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

const API = (
  methodType: any,
  endpoint: string,
  payload?: any,
  token?: string,
  signal?: AbortSignal
): AxiosPromise => {
  const headers: any = {
    'Content-Type': 'application/json',
  };
  if (token) {
    if (token.includes('Bearer')) {
      headers['Authorization'] = token;
    } else {
      headers['auth-token'] = token;
    }
  }
  const config: AxiosRequestConfig = {
    method: methodType,
    url: endpoint,
    data: payload,
    headers,
  };
  if (signal) {
    config.signal = signal;
  }
  return axios(config);
};
export default API;
