import React from 'react'
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

export interface AlertProps extends SnackbarOrigin {
    severityType: 'error' | 'info' | 'success' | 'warning'
    show: boolean
    handleClose: () => void
    message?: string
}

export const AlertBar: React.FC<AlertProps> = ({
    vertical,
    horizontal,
    severityType,
    show,
    handleClose,
    message,
}) => (
    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={show}
        autoHideDuration={10000}
        onClose={handleClose}
        data-testid="test alert"
    >
        <Alert variant="filled" severity={severityType} onClose={handleClose}>
            {message}
        </Alert>
    </Snackbar>
)
