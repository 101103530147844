import React from 'react'
import Box, { BoxProps } from '@material-ui/core/Box'

export const BoxUly: React.FC<BoxProps> = React.memo((props) => {
    const { display = 'flex', alignItems = 'center', children } = props
    return (
        <Box display={display} alignItems={alignItems} {...props}>
            {children}
        </Box>
    )
})
BoxUly.displayName = 'BoxUly'
