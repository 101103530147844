import React from 'react'
import Menu, { MenuProps } from '@material-ui/core/Menu'

export const MenuUly: React.FC<MenuProps> = React.memo(
    ({
        open,
        PaperProps = {
            elevation: 5,
        },
        ...rest
    }) => <Menu keepMounted open={open} PaperProps={PaperProps} {...rest} />
)
MenuUly.displayName = 'MenuUly'
