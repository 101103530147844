const translation = {
    PAGE_TITLE: {
        CASES: `Cases`,
        AGENTS: `Agents`,
    },
    MESSAGE: {
        FORGOT_PASSWORD: {
            BOLD: `Need New Password?`,
            NORMAL: `Enter your account email address and we'll get you back on track!`,
        },
        RESET_PASSWORD: {
            BOLD: `Create a Strong Password`,
            NORMAL: `To activate your account, choose a strong password and don't reuse it for other accounts.`,
            GREY: `Must be a minimum of 12 characters, containing a mix of upper and lower case letters and numbers.`,
        },
        TEAM_MEMBER: {
            GREY: `Manage the info for role hierarchy of your team.`,
        },
    },
    SUBTITLE: {
        ASSIGNMENT: `Assignment`,
        QUEUES: `Queues`,
    },
    LABEL: {
        USERNAME: `Username`,
        PASSWORD: `Password`,
        CONFIRM_PASSWORD: `Confirm Password`,
        EMAIL: `Email Address`,
        FILTERS: `Filters`,
    },
    BUTTON: {
        LOGIN: `Log In`,
        SUBMIT: `Submit`,
        LOG_OFF: `Log Off`,
        CANCEL: `Cancel`,
        SAVE: `Save`,
        ALL_FILTERS: `All Filters`,
    },
    LINK: {
        FORGOT_PASSWORD: `Forgot Password?`,
    },
    ERROR_MESSAGE: {
        INVALID_LOGIN_ATTEMPT: `Invalid user name or password.`,
        PASSWORD: {
            MATCH: `Password do not match.`,
            COMMON: `Password is too common.`,
            NUMBER: `Must contain a number.`,
            UPPER_LOWER_CASE: `Must contain a mixture of upper and lowercase.`,
            MINIMUM: `Must contain a minimum of 12 characters.`,
            SPACES: `Password cannot have leading or trailing spaces.`,
        },
    },
}

export default translation
