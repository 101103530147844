import React from 'react';

interface State {
  error: any;
  errorInfo: any;
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<{}, State> {
  state = { error: null, errorInfo: null, hasError: false };
  static getDerivedStateFromError(): object {
    return { hasError: true };
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <h1>Error Boundary!</h1>;
    }

    return this.props.children;
  }
}
