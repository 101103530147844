import * as React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'

import styles from './Loader.module.scss'

export interface Props {
    show: boolean
    light?: boolean
}

export const Loader: React.FC<Props> = (props) => {
    const { show, light, children } = props
    return (
        <Backdrop
            classes={{
                root: clsx(styles.overlay, light && styles.overlay_light),
            }}
            open={show}
            data-testid="loader"
        >
            <CircularProgress
                size={50}
                thickness={5}
                className={styles.circular}
                color="inherit"
            />
            {children}
        </Backdrop>
    )
}
