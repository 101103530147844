import React from 'react';
import { translations } from '../../constants/languageEn';
import { BoxUly, Message } from '../../uly-component';
import { SUNDIAL_ICON } from '../../utils/images';

const LinkExpired: React.FC = () => (
  <BoxUly
    flexDirection="column"
    justifyContent="center"
    textAlign="center"
    padding="0 20px"
    gridGap="20px"
    height="100vh"
  >
    <img src={SUNDIAL_ICON} alt="sundial" />
    <Message type="bold" message={translations.LINK_EXPIRED} />
    <Message message={translations.NO_LONGER_AVAILABLE} />
  </BoxUly>
);

export default LinkExpired;
