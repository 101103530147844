export const HOSTURL = process.env.REACT_APP_API_HOST;
export const HOSTURL_V2 = process.env.REACT_APP_API_HOST_V2;
export const PAYMENT_APP_HOST = String(process.env.REACT_APP_PAYMENT_APP_HOST);
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const INDUSTRY = process.env.REACT_APP_INDUSTRY;
export const DEVICE = process.env.REACT_APP_DEVICE;
export const APP_TYPE = process.env.REACT_APP_APP_TYPE;
export const HTTP_POST = `POST`;
export const HTTP_PUT = `PUT`;
export const HTTP_GET = `GET`;

export const GET_TOKEN_API = (transectionCode: string): string =>
  `${HOSTURL}/v4/clients/verify/token/${transectionCode}`;

export const GET_JOB_DETAILS = (jobId: string): string =>
  `${HOSTURL}/v4/customer-jobs/${jobId}`;

export const GET_TIME_SLOT = (
  timeZone: string,
  partnerCode: string,
  date?: string
): string =>
  `${HOSTURL_V2}/partner-management-v1/time-slots?partnerCode=${partnerCode}&timeZone=${timeZone}&selectedDate=${date}`;

export const POST_SLOT_API = (jobNumber: number): string =>
  `${HOSTURL}/v4/job/${jobNumber}/change-schedule`;

export const TIME_SLOT_CONFIRMATION_API = (jobNumber: number): string =>
  `${HOSTURL}/v4/job/${jobNumber}/secondary-tow-data`;
