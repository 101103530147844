import React from 'react';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { translations } from '../constants/languageEn';
import { routes } from '../constants/routes';
import {
  ChosenSlot,
  ErrorHandler,
  JobDetails,
  SetState,
  Slot,
} from '../types/types';
import {
  timeSlotsProcessing,
  dateStringHandler,
  slotEndHandler,
  timeStringHandler,
  time_re,
} from '../utils/common';
import { getInitialData, fetchTimeSlot } from '../utils/fetch';
import { AxiosError } from 'axios';

interface UseInitial {
  isLoading: boolean;
  timeSlots: Slot;
  chosenSlot: ChosenSlot;
  jobDetails: JobDetails;
  startDate: string;
  error: string;
  setIsLoading: SetState<boolean>;
  setChosenSlot: SetState<ChosenSlot>;
  errorHandler: ErrorHandler;
}

let initial = true;

export default function useInitial(): UseInitial {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [timeSlots, setTimeSlots] = React.useState<Slot>([]);
  const [chosenSlot, setChosenSlot] = React.useState<ChosenSlot>({
    date: '',
    time: '',
  });
  const [jobDetails, setJobDetails] = React.useState<JobDetails>({
    internalAccessToken: '',
    jobNumber: undefined,
    serviceMessage: '',
    timeZone: '',
    partnerCode: '',
  });

  const navigate = useNavigate();

  const errorHandler = React.useCallback((arg) => {
    if (arg.type) {
      setError('');
    } else {
      const err = arg as AxiosError;
      if (
        err.response?.data?.message?.includes('The Token has expired') ||
        err.response?.data?.error?.includes('The Token has expired')
      ) {
        navigate(routes.LINK_EXPIRED);
        return;
      }
      setError(
        err.response?.data?.error ??
          err.response?.data?.message ??
          err.message ??
          translations.HAVING_TROUBLES
      );
    }
  }, []); //eslint-disable-line

  const slotsDataProcessing = (
    slotData: any[],
    timeZone: string,
    nextDay: boolean,
    scheduledAt?: number
  ): void => {
    const today = moment().tz(timeZone).startOf('day');
    let startDay = '';
    if (nextDay) {
      startDay = dateStringHandler(today.clone().add(1, 'days'));
      setStartDate(startDay);
      !scheduledAt && setChosenSlot((prev) => ({ ...prev, date: startDay }));
      return;
    }
    startDay = dateStringHandler(today);
    setStartDate(startDay);
    !scheduledAt && setChosenSlot((prev) => ({ ...prev, date: startDay }));
    setTimeSlots(timeSlotsProcessing(slotData));
  };

  const createChosenSlot = (
    sheduled: number,
    timezone: string,
    slotDuration = 1
  ): void => {
    const sheduledAt = moment(sheduled).tz(timezone);
    const slotStart = time_re.exec(sheduledAt.format())![0]; //eslint-disable-line
    const slotEnd = slotEndHandler(slotStart, slotDuration);
    setChosenSlot({
      date: dateStringHandler(sheduledAt),
      time: timeStringHandler(slotStart, slotEnd),
    });
  };

  const getData = async (transactionCode: string): Promise<any> => {
    try {
      const { slotData, jobDeatils, nextDay } = await getInitialData(
        transactionCode
      );
      const {
        scheduledAt,
        timeZone,
        internalAccessToken,
        jobNumber,
        serviceMessage,
        partnerCode,
      } = jobDeatils;
      slotsDataProcessing(slotData, timeZone, nextDay, scheduledAt);
      scheduledAt &&
        createChosenSlot(scheduledAt, timeZone, slotData[0]?.slotDuration);
      setJobDetails({
        internalAccessToken,
        jobNumber,
        serviceMessage,
        timeZone,
        partnerCode,
      });
      setIsLoading(false);
      navigate(routes.SCHEDULED_SERVICES);
    } catch (error) {
      setIsLoading(false);
      navigate(routes.LINK_EXPIRED);
    }
  };

  React.useEffect(() => {
    const { searchParams } = new URL(location.href.replace('/#', ''));
    const transactionCode = searchParams.get('transactionCode');

    if (!transactionCode) {
      setIsLoading(false);
      navigate(routes.LINK_EXPIRED);
      return;
    }
    getData(transactionCode);
  }, []); //eslint-disable-line

  const { date: chosenDate } = chosenSlot;
  const getTimeSlots = React.useCallback(() => {
    const { internalAccessToken, timeZone, partnerCode } = jobDetails;
    setIsLoading(true);
    fetchTimeSlot(
      timeZone,
      internalAccessToken,
      partnerCode,
      moment(chosenDate.split('T')[0]).format('L')
    )
      .then((data) => {
        setIsLoading(false);
        if (data[0]) {
          setTimeSlots(timeSlotsProcessing(data));
        } else {
          setTimeSlots([]);
        }
      })
      .catch((e) => {
        setIsLoading(false);

        errorHandler(e);
      });
  }, [chosenDate, jobDetails]); //eslint-disable-line

  React.useEffect(() => {
    if (initial) {
      if (chosenDate) initial = false;
      return;
    }
    getTimeSlots();
  }, [chosenDate]); //eslint-disable-line

  return {
    isLoading,
    error,
    timeSlots,
    startDate,
    chosenSlot,
    jobDetails,
    setIsLoading,
    setChosenSlot,
    errorHandler,
  };
}
