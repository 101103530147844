import React from 'react';
import { translations } from '../../constants/languageEn';
import { StageType } from '../../types/types';
import { Message } from '../../uly-component';

export interface MessageProps {
  stage: StageType;
}

export const ServiceMessage: React.FC<MessageProps> = React.memo(
  ({ stage }) => {
    let message;
    switch (stage) {
      case 'starting':
        message = translations.PLEASE_CONFIRM;
        break;
      case 'updating':
        message = translations.UPDATE_AND_CONFIRM;
        break;
      case 'confirmed':
        message = translations.SERVICE_CONFIRMED;
        break;
      default:
        message = translations.PLEASE_CONFIRM;
    }
    return <Message color="primary" message={message} align="center" />;
  }
);

ServiceMessage.displayName = 'ServiceMessage';
