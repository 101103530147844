import React from 'react';
import { translations } from '../../constants/languageEn';
import { ChosenSlot, Slot, StageType } from '../../types/types';
import { BoxUly, Message, TextInput } from '../../uly-component';
import { TimeSlot } from './TimeSlot/TimeSlot';

export interface TimeSlotProps {
  stage: StageType;
  startDate: string;
  times: Slot;
  slot: ChosenSlot;
  timeError: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Slots: React.FC<TimeSlotProps> = ({
  stage,
  startDate,
  times,
  slot,
  timeError,
  onChange,
}) => {
  const { date, time } = slot;
  return stage === 'updating' ? (
    <>
      <TextInput
        label={translations.DATE}
        type="date"
        fullWidth
        defaultValue={date.split('T')[0]}
        variant="outlined"
        onChange={onChange}
        inputProps={{ min: startDate.split('T')[0] }}
        InputLabelProps={{ shrink: true }}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        required
      />
      <TimeSlot
        label={
          times[0]
            ? translations.AVAILABLE_TIME
            : translations.CHOOSE_ANOTHER_DATE
        }
        value={times.includes(time) ? time : ''}
        onChange={onChange}
        data={times}
        error={timeError}
        disabled={!times[0]}
        required
      />
    </>
  ) : (
    <BoxUly flexDirection="column">
      <Message align="center" message={date.split('&')[1]} type="bold" />
      <Message align="center" message={time.split('&')[1]} type="bold" />
    </BoxUly>
  );
};
