import moment from 'moment-timezone';
import { Slot } from '../types/types';

export const getBearerToken = (token: string): string => `Bearer ${token}`;

//date and time start
export const time_re = /\d{2}:\d{2}:\d{2}/;

export const TIME = '00:00:00';

export const timeTo12h = (time: string): string => {
  return new Date('1970-01-01T' + time + 'Z').toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });
};

const addZero = (val: string | number): string => {
  const stringed = String(val);
  return stringed.length === 1 ? `0${stringed}` : stringed;
};

export const dateStringHandler = (date: moment.Moment): string => {
  const value = date.format().replace(time_re, TIME);
  const label = date.format('llll').split(',').slice(0, 2).join(''); //output: Wed Mar 16
  return `${value}&${label}`;
};

export const createDates = (timezone: string, days = 90): Slot => {
  const datesArray: Slot = [];
  for (let i = 0; i < days; i++) {
    const date = moment().tz(timezone);
    i !== 0 && date.add(i, 'days');
    datesArray.push(dateStringHandler(date));
  }
  return datesArray;
};

export const timeStringHandler = (start: string, end: string): string => {
  const label = timeTo12h(start) + ' - ' + timeTo12h(end);
  return `${start}&${label}`;
};

export const slotEndHandler = (slotStart: string, duration: number): string => {
  return slotStart.replace(/\d{2}/, (match) => {
    const formattedTime = addZero(+match + duration);
    return formattedTime;
  });
};

export const timeSlotsProcessing = (data: any[]): Slot =>
  data.map(({ slotStart, slotEnd }) => timeStringHandler(slotStart, slotEnd));
//date and time end

export const getScheduledPayload = (scheduledAt: number): object => ({
  assignCurrentProvider: true,
  assignCurrentVehicle: true,
  jobType: 'RSA_SCHEDULED_SERVICE',
  scheduledAt,
});
