import React from 'react'
import clsx from 'clsx'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import styles from './Message.module.scss'

export type MessageProps = {
    message?: string
    type?: 'bold' | 'grey'
} & TypographyProps

export const Message: React.FC<MessageProps> = React.memo((props) => {
    const { message, type, children, className = '' } = props
    return (
        <Typography
            variant={type === 'bold' ? 'h6' : 'body1'}
            className={clsx({
                [styles.bold]: type === 'bold',
                className,
            })}
            {...props}
        >
            {message || children}
        </Typography>
    )
})
Message.displayName = 'Message'
