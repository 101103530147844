import React, { ChangeEvent } from 'react'
import { ButtonUly } from '../../components/Button/Button'
import { TextInput } from '../../components/TextInput/TextInput'
import { Layout } from '../../components/Layout/Layout'
import { Message } from '../../components/Message/Message'
import { useFocus } from '../../custom/useFocus'

export interface ForgotPasswordProps {
    newPassword?: string
    enterEmail?: string
    submitText?: string
    disabled: boolean
    error: boolean
    onChange: (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void
    onClick: () => void
}

const NEW_PASSWORD = 'Need New Password?'
const ENTER_EMAIL =
    "Enter your account email address and we'll get you back on track!"
const SUBMIT = 'SUBMIT'

const ForgotPasswordContent: React.FC<ForgotPasswordProps> = ({
    newPassword = NEW_PASSWORD,
    enterEmail = ENTER_EMAIL,
    submitText = SUBMIT,
    disabled,
    error,
    onChange,
    onClick,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    useFocus(inputRef)
    return (
        <>
            <Message message={newPassword} type="bold" />
            <Message message={enterEmail} />
            <TextInput
                fullWidth
                inputRef={inputRef}
                type="email"
                error={error}
                onChange={onChange}
            />
            <ButtonUly
                variant="contained"
                color="secondary"
                onClick={onClick}
                disabled={disabled}
            >
                {submitText}
            </ButtonUly>
        </>
    )
}

export const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({
    newPassword,
    enterEmail,
    submitText,
    disabled,
    error,
    onChange,
    onClick,
}) => (
    <Layout backgroundColor="primary" center>
        <ForgotPasswordContent
            newPassword={newPassword}
            enterEmail={enterEmail}
            submitText={submitText}
            error={error}
            disabled={disabled}
            onChange={onChange}
            onClick={onClick}
        />
    </Layout>
)
