import React, { ChangeEvent } from 'react'
import translation from '../../assets/languages/languageEN'
import { Layout } from '../../components/Layout/Layout'
import { Message } from '../../components/Message/Message'
import { TextInput } from '../../components/TextInput/TextInput'
import { ButtonUly } from '../../components/Button/Button'
import { useFocus } from '../../custom/useFocus'

export interface ResetPasswordProps {
    title?: string
    choosePassword?: string
    passwordLabel?: string
    confirmpasswordLabel?: string
    passwordRules?: string
    submitBtnText?: string
    disabled: boolean
    onClick: () => void
    onChangePassword: (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void
    onChangeConfirmPassword: (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void
    errorMsgPassword?: string
    errorMsgMatch?: string
}

const TITLE = translation.MESSAGE.RESET_PASSWORD.BOLD
const CHOOSE_PASSWORD = translation.MESSAGE.RESET_PASSWORD.NORMAL
const PASSWORD_LABEL = translation.LABEL.PASSWORD
const CONFIRM_PASSWORD_LABEL = translation.LABEL.CONFIRM_PASSWORD
const PASSWORD_RULES = translation.MESSAGE.RESET_PASSWORD.GREY
const SUBMIT_BTN_TEXT = translation.BUTTON.SUBMIT

const ResetPasswordContent: React.FC<ResetPasswordProps> = ({
    title = TITLE,
    choosePassword = CHOOSE_PASSWORD,
    passwordLabel = PASSWORD_LABEL,
    confirmpasswordLabel = CONFIRM_PASSWORD_LABEL,
    passwordRules = PASSWORD_RULES,
    submitBtnText = SUBMIT_BTN_TEXT,
    disabled,
    onClick,
    onChangePassword,
    onChangeConfirmPassword,
    errorMsgPassword = undefined,
    errorMsgMatch = undefined,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    useFocus(inputRef)
    return (
        <>
            <Message message={title} type="bold" />
            <Message message={choosePassword} />
            <TextInput
                inputRef={inputRef}
                label={passwordLabel}
                type="new_password"
                error={!!errorMsgPassword}
                helperText={errorMsgPassword || ' '}
                onChange={onChangePassword}
            />
            <Message message={passwordRules} color="textSecondary" />
            <TextInput
                label={confirmpasswordLabel}
                type="new_password"
                error={!!errorMsgMatch}
                helperText={errorMsgMatch || ' '}
                onChange={onChangeConfirmPassword}
            />
            <ButtonUly
                variant="contained"
                color="secondary"
                onClick={onClick}
                disabled={disabled}
            >
                {submitBtnText}
            </ButtonUly>
        </>
    )
}

export const ResetPasswordForm: React.FC<ResetPasswordProps> = ({
    title,
    choosePassword,
    passwordLabel,
    confirmpasswordLabel,
    passwordRules,
    submitBtnText,
    disabled,
    onClick,
    onChangePassword,
    onChangeConfirmPassword,
    errorMsgPassword,
    errorMsgMatch,
}) => (
    <Layout backgroundColor="primary" center>
        <ResetPasswordContent
            title={title}
            choosePassword={choosePassword}
            passwordLabel={passwordLabel}
            confirmpasswordLabel={confirmpasswordLabel}
            passwordRules={passwordRules}
            submitBtnText={submitBtnText}
            disabled={disabled}
            onClick={onClick}
            onChangePassword={onChangePassword}
            onChangeConfirmPassword={onChangeConfirmPassword}
            errorMsgPassword={errorMsgPassword}
            errorMsgMatch={errorMsgMatch}
        />
    </Layout>
)
