import React from 'react'
import { DataGrid, GridOverlay, DataGridProps } from '@material-ui/data-grid'

export interface DataGridTableProps extends DataGridProps {
    noRowsOverlay?: React.ReactNode
}

export const GridTableUly: React.FC<DataGridTableProps> = React.memo(
    ({ columns, rows, noRowsOverlay, ...rest }) => {
        const [pageSize, setPageSize] = React.useState(5)
        const NoRowsOverlay = React.useCallback(
            () => <GridOverlay>{noRowsOverlay}</GridOverlay>,
            [noRowsOverlay]
        )
        return (
            <DataGrid
                rows={rows}
                columns={columns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={rows[0] && [5, 10, 15, rows.length]}
                disableSelectionOnClick
                disableColumnMenu
                components={{
                    NoRowsOverlay,
                }}
                {...rest}
            />
        )
    }
)

GridTableUly.displayName = 'GridTableUly'
