import moment from 'moment-timezone';
import {
  GET_JOB_DETAILS,
  GET_TIME_SLOT,
  GET_TOKEN_API,
  POST_SLOT_API,
  TIME_SLOT_CONFIRMATION_API,
} from '../constants/API';
import { translations } from '../constants/languageEn';
import API from './API';
import { getBearerToken, getScheduledPayload } from './common';

const getInitialDetails = async (transactionCode: string): Promise<any> => {
  const response = await API('get', GET_TOKEN_API(transactionCode));
  if (!response?.data) throw new Error();
  if (response?.data?.errorCode) throw new Error(response?.data);
  const { internalAccessToken, jobNumber, partnerCode } = response.data;
  return { internalAccessToken, jobNumber, partnerCode };
};

const getJobDetails = async (transactionCode: string): Promise<any> => {
  const { internalAccessToken, jobNumber, partnerCode } =
    await getInitialDetails(transactionCode);
  const response = await API(
    'get',
    GET_JOB_DETAILS(jobNumber),
    '',
    getBearerToken(internalAccessToken)
  );
  if (!response?.data) throw new Error();
  const { scheduledAt, name } = response.data.service;
  const { year = '', make = '', model = '' } = response.data.vehicle;
  return {
    partnerCode,
    internalAccessToken,
    timeZone: response.data.location.timeZone,
    scheduledAt: scheduledAt,
    jobNumber,
    serviceMessage:
      `${name} ${translations.FOR} ${year} ${make} ${model}`.toUpperCase(),
  };
};

export const getInitialData = async (transactionCode: string): Promise<any> => {
  const jobDeatils = await getJobDetails(transactionCode);
  const { timeZone, internalAccessToken, partnerCode, scheduledAt } =
    jobDeatils;
  const scheduled = scheduledAt
    ? moment(scheduledAt).tz(timeZone).format('L')
    : moment().tz(timeZone).format('L');
  let response = await API(
    'get',
    GET_TIME_SLOT(timeZone, partnerCode, scheduled),
    '',
    internalAccessToken
  );
  if (!response.data) throw new Error();
  let nextDay = false;
  /*fetching slots for the next day if no avilible slots for today*/
  if (!scheduledAt && !response.data[0]) {
    nextDay = true;
    response = await API(
      'get',
      GET_TIME_SLOT(
        timeZone,
        partnerCode,
        moment().add('1', 'day').tz(timeZone).format('L')
      ),
      '',
      internalAccessToken
    );
  }
  return { slotData: response.data, jobDeatils, nextDay };
};

export const fetchTimeSlot = async (
  timeZone: string,
  internalAccessToken: string,
  partnerCode: string,
  date: string
): Promise<any> => {
  const response = await API(
    'get',
    GET_TIME_SLOT(timeZone, partnerCode, date),
    '',
    internalAccessToken
  );
  if (!response.data) throw new Error();
  return response.data;
};

export const postTimeSlot = (
  jobNumber: number,
  scheduledAt: number,
  token: string
): Promise<any> =>
  API(
    'post',
    POST_SLOT_API(jobNumber),
    getScheduledPayload(scheduledAt),
    token
  );

export const timeSlotConfirmation = (
  jobNumber: number,
  token: string
): Promise<any> =>
  API(
    'post',
    TIME_SLOT_CONFIRMATION_API(jobNumber),
    {
      timeslotConfirmationStatus: 'CONFIRMED',
    },
    token
  );
