import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translations } from '../../../constants/languageEn';
import { Slot } from '../../../types/types';
import { TextInput } from '../../../uly-component';

export interface SlotSelectProps {
  data: Slot;
  label: string;
  value: string;
  disabled?: boolean;
  required?: boolean;
  error: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TimeSlot: React.FC<SlotSelectProps> = React.memo(
  ({ data, label, value, disabled, required, onChange, error }) => {
    return (
      <TextInput
        data-testid={label}
        label={label}
        variant="outlined"
        fullWidth
        select
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        helperText={error || (!data[0] && translations.NO_TIME_SLOTS)}
        error={!!error}
      >
        {data.map((item: string) => {
          const label = item.split('&')[1];
          return (
            <MenuItem key={item} value={item}>
              {label}
            </MenuItem>
          );
        })}
      </TextInput>
    );
  }
);

TimeSlot.displayName = 'TimeSlot';
