import React from 'react'
import {
    FormLabel,
    FormLabelProps,
    RadioGroup,
    RadioGroupProps,
    FormControlLabel,
    Radio,
    RadioProps,
    Switch,
    SwitchProps,
    CheckboxProps,
    Checkbox,
    StandardProps,
} from '@material-ui/core'

export interface FormLabelUlyProps extends FormLabelProps {
    component?: any
    href?: string
    target?: string
}

export const FormLabelUly: React.FC<FormLabelUlyProps> = React.memo(
    ({ component = 'legend', ...rest }) => (
        <FormLabel component={component} {...rest} />
    )
)
FormLabelUly.displayName = 'FormLabelUly'

export const RadioGroupUly: React.FC<RadioGroupProps> = React.memo((props) => (
    <RadioGroup {...props} />
))
RadioGroupUly.displayName = 'RadioGroupUly'

export const RadioUly: React.FC<RadioProps> = React.memo((props) => (
    <Radio color="primary" {...props} />
))
RadioUly.displayName = 'RadioUly'

export const SwitchUly: React.FC<SwitchProps> = React.memo((props) => (
    <Switch color="primary" {...props} />
))
SwitchUly.displayName = 'SwitchUly'

export const CheckBoxUly: React.FC<CheckboxProps> = React.memo((props) => (
    <Checkbox color="primary" {...props} />
))
CheckBoxUly.displayName = 'CheckBoxUly'

export interface FormControlLabelUlyProps
    extends StandardProps<
        React.LabelHTMLAttributes<HTMLLabelElement>,
        FormControlLabelClassKey,
        'children' | 'onChange'
    > {
    cntrl: 'radio' | 'switch' | 'checkbox'
    cntrlName?: string
    cntrlProps?: RadioProps | CheckboxProps | SwitchProps
    checked?: boolean
    disabled?: boolean
    inputRef?: React.Ref<any>
    label: React.ReactNode
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
    name?: string
    onChange?: (event: React.ChangeEvent<{}>, checked: boolean) => void
    value?: unknown
}

export const FormControlLabelUly: React.FC<FormControlLabelUlyProps> =
    React.memo(({ cntrl, cntrlName, cntrlProps, ...rest }) => {
        let controlUly
        switch (cntrl) {
            case 'radio':
                controlUly = <RadioUly name={cntrlName} {...cntrlProps} />
                break
            case 'checkbox':
                controlUly = <CheckBoxUly name={cntrlName} {...cntrlProps} />
                break
            case 'switch':
                controlUly = <SwitchUly name={cntrlName} {...cntrlProps} />
                break
            default:
                throw new Error()
        }
        return <FormControlLabel control={controlUly} {...rest} />
    })
FormControlLabelUly.displayName = 'FormControlLabelUly'

export type FormControlLabelClassKey =
    | 'root'
    | 'labelPlacementStart'
    | 'labelPlacementTop'
    | 'labelPlacementBottom'
    | 'disabled'
    | 'label'
