/* eslint-disable react/display-name */
import React from 'react'
import Link, { LinkProps } from '@material-ui/core/Link'
import Typography, { TypographyProps } from '@material-ui/core/Typography'

export const LinkUly: React.FC<LinkProps & TypographyProps> = React.memo(
    (props) => {
        const { underline = 'none' } = props
        return (
            <Link underline={underline} {...props}>
                <Typography>{props.children}</Typography>
            </Link>
        )
    }
)
