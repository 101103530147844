import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { routes } from './constants/routes';
import { ErrorBoundary } from './containers/ErrorBoundary';
import { SheduledService } from './containers/SheduledService';
import useInitial from './customHooks/useInitial';
import LinkExpired from './pages/linkExpired/LinkExpired';
import { AlertBar, Loader } from './uly-component';

const App: React.FC = () => {
  const {
    isLoading,
    error,
    timeSlots,
    startDate,
    chosenSlot,
    jobDetails,
    setIsLoading,
    setChosenSlot,
    errorHandler,
  } = useInitial();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Outlet />
            <Loader show={isLoading} />
            <AlertBar
              severityType="error"
              message={error}
              show={!!error}
              horizontal="center"
              vertical="top"
              handleClose={errorHandler}
            />
          </>
        }
      >
        <Route
          path={routes.SCHEDULED_SERVICES}
          element={
            <ErrorBoundary>
              <SheduledService
                timeSlots={timeSlots}
                startDate={startDate}
                chosenSlot={chosenSlot}
                jobDetails={jobDetails}
                isLoading={isLoading}
                setChosenSlot={setChosenSlot}
                setIsLoading={setIsLoading}
                errorHandler={errorHandler}
              />
            </ErrorBoundary>
          }
        />
        <Route
          path={routes.LINK_EXPIRED}
          element={
            <ErrorBoundary>
              <LinkExpired />
            </ErrorBoundary>
          }
        />
      </Route>
    </Routes>
  );
};

export default App;
