import React from 'react'
import Chip, { ChipProps } from '@material-ui/core/Chip'

export interface ChipUlyProps extends ChipProps {
    component?: string | React.FC
    href?: string
    target?: string
}

export const ChipUly: React.FC<ChipUlyProps> = React.memo((props) => (
    <Chip variant="outlined" {...props} />
))

ChipUly.displayName = 'ChipUly'
