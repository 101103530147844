export const translations = {
  PLEASE_CONFIRM: 'Please confirm the time slot for your scheduled service.',
  UPDATE_AND_CONFIRM:
    'Update and confirm the time slot for your scheduled service.',
  SERVICE_CONFIRMED: `You're scheduled service has been confirmed!`,
  DATE: 'DATE',
  AVAILABLE_TIME: 'AVAILABLE TIME SLOT',
  CHOOSE_ANOTHER_DATE: 'PLEASE, CHOOSE ANOTHER DATE',
  NO_TIME_SLOTS: 'NO AVAILABLE TIME SLOTS',
  SELECT_TIME: 'Please, select available time.',
  CHANGE_DATE: 'Change Date and Time Slot',
  SECONDARY_TOW: 'SECONDARY TOW',
  CONFIRM: 'Confirm',
  FOR: 'for',

  LINK_EXPIRED: 'Link Expired',
  NO_LONGER_AVAILABLE: 'This page is not longer available.',
  HAVING_TROUBLES: 'We are having troubles right now. Please try later.',
};
