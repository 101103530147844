import React from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'

export type Props = {
    clx?: string
} & ButtonProps

// eslint-disable-next-line react/display-name
export const ButtonUly: React.FC<Props> = React.memo((props): any => (
    <Button className={props.clx} {...props}>
        {props.children}
    </Button>
))
