import moment from 'moment-timezone';
import React from 'react';
import { ServiceMessage } from '../components/ServiceMessage/ServiceMessage';
import { Slots } from '../components/Slots/Slots';
import { translations } from '../constants/languageEn';
import {
  ChosenSlot,
  SetState,
  Slot,
  StageType,
  JobDetails,
  ErrorHandler,
} from '../types/types';
import { BoxUly, ButtonUly, LinkUly, Message } from '../uly-component';
import { dateStringHandler, getBearerToken, time_re } from '../utils/common';
import { postTimeSlot, timeSlotConfirmation } from '../utils/fetch';
import { CALENDAR_ICON } from '../utils/images';

export interface SheduledServiceProps {
  timeSlots: Slot;
  startDate: string;
  chosenSlot: ChosenSlot;
  jobDetails: JobDetails;
  isLoading: boolean;
  setIsLoading: SetState<boolean>;
  setChosenSlot: SetState<ChosenSlot>;
  errorHandler: ErrorHandler;
}

export const SheduledService: React.FC<SheduledServiceProps> = ({
  timeSlots,
  startDate,
  chosenSlot,
  jobDetails,
  isLoading,
  setIsLoading,
  setChosenSlot,
  errorHandler,
}) => {
  const [stage, setStage] = React.useState<StageType>('starting');
  const [scheduledAt, setScheduledAt] = React.useState<number>();
  const [timeError, setTimeError] = React.useState('');

  const updateDate = React.useCallback(() => {
    setStage('updating');
  }, []);

  /*in case if sheduledAt not provided in jobDetails response redirecting to update stage*/
  React.useEffect(() => {
    if (!chosenSlot.time) {
      updateDate();
    }
  }, []); //eslint-disable-line

  const chosenSlotHandler = React.useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = target;
      if (/[AP]M/.test(value)) {
        setChosenSlot((prev) => ({ ...prev, time: value }));
        setTimeError('');
      } else {
        const dateString = dateStringHandler(
          moment.tz(value, jobDetails.timeZone)
        );
        setChosenSlot((prev) => ({ ...prev, date: dateString }));
      }
    },
    [setChosenSlot] //eslint-disable-line
  );

  const onConfirm = React.useCallback(() => {
    const { date, time } = chosenSlot;
    if (!time && timeSlots[0]) {
      setTimeError(translations.SELECT_TIME);
      return;
    }
    const dateValue = date.split('&')[0];
    const timeValue = time.split('&')[0];
    const updatedDate = dateValue.replace(time_re, timeValue); //replacing time of date string with starting time of the selected slot
    const scheduledAt = moment(updatedDate).valueOf();
    setScheduledAt(scheduledAt);
  }, [chosenSlot, timeSlots]);

  React.useEffect(() => {
    scheduledAt && postScheduled(scheduledAt);
  }, [scheduledAt]); //eslint-disable-line

  const postScheduled = async (scheduledAt: number): Promise<any> => {
    const { internalAccessToken, jobNumber } = jobDetails;
    if (!jobNumber) return;
    const token = getBearerToken(internalAccessToken);
    setIsLoading(true);
    try {
      await postTimeSlot(jobNumber, scheduledAt, token);
      await timeSlotConfirmation(jobNumber, token);
      setStage('confirmed');
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
      setScheduledAt(undefined);
    }
  };

  return (
    <>
      <BoxUly
        flexDirection="column"
        gridGap="30px"
        justifyContent="center"
        margin="auto"
        height="100vh"
        maxWidth="900px"
        padding="20px"
        style={{ opacity: isLoading ? 0 : 1 }}
      >
        <img src={CALENDAR_ICON} alt="CALENDAR_ICON" />
        <ServiceMessage stage={stage} />
        <Slots
          onChange={chosenSlotHandler}
          slot={chosenSlot}
          startDate={startDate}
          times={timeSlots}
          stage={stage}
          timeError={timeError}
        />
        <Message
          align="center"
          message={jobDetails.serviceMessage}
          color="textSecondary"
        />
        {stage !== 'confirmed' && (
          <ButtonUly
            onClick={onConfirm}
            variant="contained"
            fullWidth
            color="secondary"
          >
            {translations.CONFIRM}
          </ButtonUly>
        )}
        {stage === 'starting' && (
          <LinkUly onClick={updateDate} color="secondary">
            {translations.CHANGE_DATE}
          </LinkUly>
        )}
      </BoxUly>
    </>
  );
};
