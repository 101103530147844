import React from 'react'
import {
    List,
    ListProps,
    ListItem,
    ListItemProps,
    ListItemIcon,
    ListItemIconProps,
    ListItemText,
    ListItemTextProps,
} from '@material-ui/core'

export const ListUly: React.FC<ListProps> = React.memo((props) => (
    <List {...props} />
))
ListUly.displayName = 'ListUly'

export interface ListItemUlyProps extends ListItemProps {
    button?: any
    component?: string | React.FC | undefined
    href?: string
    target?: string
}

export const ListItemUly: React.FC<ListItemUlyProps> = React.memo(
    ({ button, ...rest }) => <ListItem button={button} {...rest} />
)
ListItemUly.displayName = 'ListItemUly'

export const ListItemIconUly: React.FC<ListItemIconProps> = React.memo(
    (props) => <ListItemIcon {...props} />
)
ListItemIconUly.displayName = 'ListItemIconUly'

export const ListItemTextUly: React.FC<ListItemTextProps> = React.memo(
    (props) => <ListItemText {...props} />
)
ListItemTextUly.displayName = 'ListItemTextUly'
